import React from 'react';
import './about.scss'
import '../../index.scss'
import History from '../history/history';

const About = () => {
    return(
        <div className='about-container'>
            <History />
            {/* <h1>This page is not yet available.</h1>
            <p>
                We appreciate your interest in our company. We hope to have
                this page ready for you soon!
            </p>
            <div className='sorry'>
                
            </div> */}
        </div>
    )
}

export default About;