import React from "react";
import { Link } from "react-router-dom";
import "./projects.scss";
import "../../index.scss";
import image from "../../media/slider4.jpg";
import hotel from "../../media/services/hotel.jpg";
import medical from "../../media/services/niag.jpg";
import comm from "../../media/services/commercial.jpg";
import devs from "../../media/services/devs.jpg";

const Projects = () => {
  return (
    <div style={{display: "flex", flexDirection:'column', width: '100%'}}>
      <div
        style={{ minHeight: 0, backgroundColor: "#004986", color: "white", display: "flex", flexDirection:'column' }}
      >
        <h1 style={{ textAlign: "center", fontWeight:'bold', marginTop:'2vh' }}>Our Work</h1>
        <div style={{display: 'flex', flexDirection:'row', justifyContent:'center'}}>
          <hr id="horiz"></hr>
        </div>
        <p className="descriptive px-sm-5 fs-4">
          At DCL, we take great pride in every project we undertake. Our depth
          of experience ranges from hospitality and QSR (Quick Service
          Restaurant) to healthcare and Sr. Living developments. But we don’t
          stop there— see our range of services to learn more. ​
        </p>
        <div className="services" style={{flexDirection:'row'}}>
          <div
            className="service"
            title="DCL Healthcare Properties' upcoming Niagara Medical Building"
          >
            <Link
              to="/projects/#commercial-development"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p>Commercial Development</p>
              <div className="service">
                <img src={medical} style={{ objectFit: "cover" }} alt="a"></img>
              </div>
            </Link>
          </div>
          <div
            className="service"
            title="Welke Customs Brokers Ltd. / Global Logistics Ltd., renovated by DCL Construction"
          >
            <Link
              to="/projects/#base-building"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p>Base Building</p>
              <div className="service">
                <img src={image} style={{ objectFit: "cover" }} alt="ass"></img>
              </div>
            </Link>
          </div>
          <div
            className="service"
            title="One of DCL Constructions' projects when it was underway"
          >
            <Link
              to="/projects/#commercial-construction"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p>Commercial Construction</p>
              <div className="service">
                <img src={comm} style={{ objectFit: "cover" }} alt="ass"></img>
              </div>
            </Link>
          </div>
          <div
            className="service"
            title="DCL is prepared to give you a chance at realizing your vision-- not matter how big or small"
          >
            <Link
              to="/projects/#land-development"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p>Land Development</p>
              <div className="service">
                <img src={devs} style={{ objectFit: "cover" }} alt="ass"></img>
              </div>
            </Link>
          </div>
          <div className="service">
            <Link
              to="/projects/#hospitality"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <p>Hospitality</p>
              <div
                className="service"
                title="The Radisson Hotel, designed and constructed by DCL Construction"
              >
                <img src={hotel} style={{ objectFit: "cover" }} alt="ass"></img>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Projects;
