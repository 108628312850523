import React, { useEffect, useState } from "react";
import "./projectpage.scss";
import "../../index.scss";
import ImageCarousel from "../imagecarousel/imagecarousel";
import n1 from "../../media/projects/niag1.jpg";
import n2 from "../../media/projects/niag2.jpg";
import n3 from "../../media/projects/niag3.jpg";
import n4 from "../../media/projects/niag4.jpg";
import n5 from "../../media/projects/niag5.jpg";
import n6 from "../../media/projects/niag6.jpg";
import r3 from "../../media/projects/rad1.webp";
import r4 from "../../media/projects/rad2.jpg";
import r1 from "../../media/projects/rad3.jpg";
import r2 from "../../media/projects/rad4.jpg";
import r5 from "../../media/projects/rad5.jpg";
import r6 from "../../media/projects/rad6.jpg";
import s1 from "../../media/projects/sky1.jpg";
import s2 from "../../media/projects/sky2.jpg";
import s6 from "../../media/projects/sky3.jpg";
import s4 from "../../media/projects/sky4.jpg";
import s5 from "../../media/projects/sky5.jpg";
import s3 from "../../media/projects/sky12.jpg";
// import 'bootstrap/dist/css/bootstrap.min.css';

const ProjectShowcase = () => {
  const [scrolled, setScrolled] = useState(0);
  const dclniagara = [n1, n2, n3, n4, n5, n6];
  const raddy = [r1, r2, r3, r4, r5, r6];
  const skyw = [s1, s2, s3, s4, s5, s6];
  useEffect(() => {
    if (scrolled === 0) {
      setScrolled(1);
      window.scrollTo(0, 0);
    }
  }, [scrolled]);

  return (
    <div className="project-showcase-container">
      <h1
        className="fade page-header fw-bold link-offset-2 fs-1"
        style={{ "--timing": "300ms", "--offset": "1.25vh" }}
      >
        Projects
      </h1>
      <div className="project-type-cont">
        <h2
          className="fade page-header mt-3 fw-semibold link-offset-2 fs-2"
          style={{ "--timing": "300ms", "--offset": "1.25vh" }}
          id="commercial-development"
        >
          Commercial Development
        </h2>
        <div className="project-info flex-lg-row flex-sm-column fs-3">
          <p flex-lg-row flex-sm-column fs-3
            className="project-desc slideDown"
            style={{ "--timing": "300ms", "--order": 1}}
          >
            <b>Featured Project - DCL Niagara Community Healthcare Hub</b>
            <br></br>
            Developed by DCL Group, the Niagara Community Healthcare Hub is
            situated on 4 acres featuring 50,000 sq. ft. of purpose-built
            healthcare and 140,000 sq. ft. of Sr. Retirement living –
            accumulating a project value of more than $100M
          </p>
          <div
            className="carousel-cont slideDown"
            style={{ "--timing": "500ms", "--order": 1}}
          >
            <ImageCarousel images={dclniagara} />
          </div>
        </div>
      </div>
      <hr className="proj-hr"></hr>
      <div className="project-type-cont">
        <h2
          className="fade page-header fw-semibold link-offset-2 fs-2"
          style={{ "--timing": "300ms", "--offset": "1.25vh" }}
          id="hospitality"
        >
          Hospitality & QSR
        </h2>
        <div className="project-info flex-lg-row flex-sm-column fs-3">
          <p
            className="project-desc slideDown"
            style={{ "--timing": "300ms", "--order": 2 }}
          >
            <b>Featured Project - The Radisson Hotel</b>
            <br></br>
            As part of the exhaustive team that built this grand project, DCL
            was proud to be part of the interior finishing of this masterpiece
            Radisson Hotel project. The images say it all.
          </p>
          <div
            className="carousel-cont slideDown"
            style={{ "--timing": "500ms", "--order": 2 }}
          >
            <ImageCarousel images={raddy} />
          </div>
        </div>
      </div>
      <hr className="proj-hr"></hr>
      <div className="project-type-cont">
        <h2
          className="fade page-header fw-semibold link-offset-2 fs-2"
          style={{ "--timing": "300ms", "--offset": "1.25vh" }}
          id="base-building"
        >
          Base Building
        </h2>
        <div className="project-info flex-lg-row flex-sm-column fs-3">
          <p
            className="project-desc slideDown"
            style={{ "--timing": "300ms", "--order": 3 }}
          >
            <b>Featured Project - Skyway</b>
            <br></br>A client came to us for a simple freshen-up of his existing
            building, and DCL took on the Design Build, turning a circa 1975
            building into a contemporary modern beauty. From design and
            structural engineering, DCL handled the entire project inhouse.
          </p>
          <div
            className="carousel-cont slideDown"
            style={{ "--timing": "500ms", "--order": 3 }}
          >
            <ImageCarousel images={skyw} />
          </div>
        </div>
      </div>
      {/* <hr className="proj-hr"></hr>
      <div className="project-type-cont">
        <h2
          className="fade page-header"
          style={{ "--timing": "300ms", "--offset": "1.25vh" }}
          id="landdev"
        >
          Land Development
        </h2>
      </div> */}
    </div>
  );
};

export default ProjectShowcase;
