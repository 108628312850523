import React from 'react';
import '../../index.scss'
import './services.scss'
import sample from '../../media/sample.MOV'

const Services = (props) => {
    return (
        <div className='services-container'>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>
            <h1 className='fade page-header fw-bold' style={{'--timing': '300ms'}}>Services</h1>
            <div className='services-desc'>
                <div className='videobg'>                    
                <h3 className='fade fs-4 text-light alerttext' 
                style={{zIndex: '1', '--timing': '300ms',
                 position: 'absolute'}}>
                    From site development to design build and construction management – DCL is your single source for cost effective rapid deployment of your next project. ​</h3>

                    <video className='videoTag fade' style={{"--timing":"300ms"}}autoPlay loop muted>
                        <source src={sample} type='video/mp4' />
                    </video>

                </div>
                <div className='service-cont flex-md-column flex-lg-row'>
                <div className='service-title serv-inner slideDown' style={{'--order': 1, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        foundation
                        </span>
                        <h2 className='fw-bold'>
                        Preconstruction</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 1, '--timing': '300ms', fontSize: 'inherit'}}>
                    DCL and its integrated team can identify the pitfalls and
                     risks of a project prior to design or construction. 
                     Site analysis goes beyond curb appeal…  Existing site 
                     and or building conditions, zoning can all impact your 
                     project. Talk to us before you sign a lease or make that 
                     decision to purchase. ​​​
                    </div>
                </div>

                <div className='service-cont flex-md-column flex-lg-row'>
                <div className='service-title serv-inner slideDown' style={{'--order': 4, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        construction
                        </span>
                        <h2 className='fw-bold'>
                        Construction</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 4, '--timing': '300ms', fontSize: 'inherit'}}>
                    The construction process is not an easy one…  
                    from design to execution, it can be a minefield.  
                    As experienced builders and construction managers, DCL has
                     a strong discipline in strategic planning, coordination, 
                     and supervision of all activities involved in the 
                     execution of a construction project, aiming to ensure 
                     its successful completion within the defined scope, 
                     schedule, budget, and quality standards. ​​
                    </div>
                </div>

                <div className='service-cont flex-md-column flex-lg-row'>
                <div className='service-title serv-inner slideDown' style={{'--order': 2, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        design_services
                        </span>
                        <h2 className='fw-bold'>
                        Design Build</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 2, '--timing': '300ms', fontSize: 'inherit'}}>
                    Design-build is where DCL shines. A method of project
                     delivery in which one entity— the design-build team, 
                     works under a single contract with the project owner 
                     to provide design and construction services 
                     simultaneously. One entity, one contract, one unified 
                     flow of work from initial concept through completion. 
                     As a Design-Builder, DCL undertakes that integrated role 
                     in the project delivery method and streamlines the 
                     deliverables.​
                    </div>
                </div>

                <div className='service-cont flex-md-column flex-lg-row'>
                <div className='service-title serv-inner slideDown' style={{'--order': 6, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        forest
                        </span>
                        <h2 className='fw-bold'>
                        Land Development &  Analysis</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 6, '--timing': '300ms', fontSize: 'inherit'}}>
                    As experienced developers, DCL knows the development 
                    business and can offer a range of services and 
                    expertise involved in transforming raw land or 
                    underutilized properties into developed sites, including 
                    land planning, engineering, permitting, and infrastructure 
                    design, to prepare the land for construction and maximize 
                    its potential value.​​
                    </div>
                </div>

                  
                <div className='service-cont flex-md-column flex-lg-row'>
                <div className='service-title serv-inner slideDown' style={{'--order': 7, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        draw
                        </span>
                        <h2 className='fw-bold'>
                        Build Envelope Repurpose</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 7, '--timing': '300ms', fontSize: 'inherit'}}>
                    With our own in–house Building envelope engineering, we 
                    can handle modeling, assessment, and simulation of a 
                    structure's external components and energy performance to 
                    optimize its thermal efficiency and sustainability. We can
                     even assist in large-scale project financing. ​​
                    </div>
                </div>

                <div className='service-cont flex-md-column flex-lg-row'>
                <div className='service-title serv-inner slideDown' style={{'--order': 5, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        finance
                        </span>
                        <h2 className='fw-bold'>
                        Capital Finance</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 5, '--timing': '300ms', fontSize: 'inherit'}}>
                    Having been involved in numerous real estate capital 
                    finance projects, we understand it. As a design-builder 
                    or construction manager, we can play a critical role in 
                    assisting owners with funding options to obtain funding 
                    and investment for real estate projects, acquisitions, or 
                    developments. ​​
                    </div>
                </div>

                <div className='service-cont flex-md-column flex-lg-row'>
                    <div className='service-title serv-inner slideDown' style={{'--order': 1, '--timing': '300ms'}}>
                        <span class="material-symbols-outlined">
                        diversity_3
                        </span>
                        <h2 className='fw-bold'>
                        Joint Ventures</h2>
                        </div>
                    <div className='service-description slideLeft' style={{'--order': 1, '--timing': '300ms', fontSize: 'inherit'}}>
                    Much of our success has been built on partnerships. If you
                     have something you feel might be of interest, we urge you 
                     to reach out to our CEO.​
                    </div>
                </div>
                
                
                
                
              
                <div className='pb-0 mb-0 cta '>
                    <h2 className='fs-3 fw-bold pt-5' >Let's discuss your next project.</h2>
                    <a href="/contact"><button className='cta-btn' >Contact Us</button></a>
                </div>
            </div>
        </div>
        
    )
}

export default Services;