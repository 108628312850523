import React from "react";
// import emailjs from "@emailjs/browser";
import "./contact.scss";
import "../../index.scss";
import telemarketer from "../../media/customer_service.png";
import CountryProvinceSelector from "../countrydropdown/countrydropdown";
import Form from 'react-bootstrap/Form';

const Contact = () => {

  // const sendEmail = (e) => {
  //   // e.preventDefault();
  //   // emailjs
  //   //   .sendForm("service_tvys1lb", "TEMPLATE_ID", form.current, "PUBLIC KEY")
  //   //   .then(
  //   //     (result) => {
  //   //       console.log(result.text);
  //   //     },
  //   //     (error) => {
  //   //       console.log(error.text);
  //   //     }
  //   //   );
  // };
  
  return (
    <div className="d-flex flex-column">

    
    <div className="contact-container d-flex flex-sm-column-reverse flex-lg-row">

      <div className="contact-a">
        <h1
          className="page-header fade fw-bold fs-1"
          style={{ "--order": 1, "--timing": "500ms" }}
        >
          Where to Find Us
        </h1>
        <div className="triple-col  d-flex flex-sm-column flex-lg-row">
          <div className="inner">
            <h3
              className="slideDown fw-semibold fs-5"
              style={{ "--order": 1, "--timing": "300ms" }}
            >
              DCL Toronto
            </h3>
            <p
              className="slideDown"
              style={{ "--order": 1.5, "--timing": "300ms" }}
            >
              RBC Tower, 3300 Hwy 7 Ste. 600 Vaughan, Ontario L4K 4M3
            </p>
          </div>
          <div className="inner">
            <h3
              className="slideDown fw-semibold fs-5"
              style={{ "--order": 2, "--timing": "300ms" }}
            >
              DCL Florida
            </h3>
            <p
              className="slideDown"
              style={{ "--order": 2.5, "--timing": "300ms" }}
            >
              7901 4th Street N, Ste. 300 St. Petersburg, Florida 33702
            </p>
          </div>
          <div className="inner">
            <h3
              className="slideDown fw-semibold fs-5"
              style={{ "--order": 3, "--timing": "300ms" }}
            >
              Contact Information
            </h3>
            <p
              className="slideDown"
              style={{ "--order": 3.5, "--timing": "300ms" }}
            >
              <a href="tel:800-280-7591" className="contact">
                Toll: 800 280-7591
              </a>
              <br></br>
              <a href="tel:416-800-8764" className="contact">
                Tel: 416 800-8764
              </a>
              <br></br>
              Fax: 416 849-0598
            </p>
          </div>
        </div>
        <img
          src={telemarketer}
          className="customer-service-img fade"
          style={{ "--timing": "1000ms" }}
          alt="Contact DCL Today!"
        ></img>
      </div>
      <div className="contact-b">
        <h1
          className="page-header fade fw-bold fs-1"
          style={{ "--order": 2, "--timing": "300ms" }}
        >
          Get In Touch With Us!
        </h1>

        <Form>
          <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Label className='fw-bold fs-3' >Name</Form.Label>
            <Form.Control type="text" className='fs-4' placeholder="Enter your name." />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className='fw-bold fs-3' >Email</Form.Label>
            <Form.Control type="email" className='fs-4' placeholder="So we can get back to you." />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicSelect">
          <CountryProvinceSelector />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicTextarea">
        <Form.Label className='fw-bold fs-3' >Proposal</Form.Label>
        <Form.Control as="textarea" className='fs-4' placeholder='Let us know your idea.' rows={10} />
      </Form.Group>
        <input
                className="fade px-5 py-2"
                style={{ "--timing": "1300ms" }}
                type="submit"
                id="submit-btn"
                value="Submit"
              />
        </Form>
      </div>
      
      </div>
      <div className="text-center text-body-tertiary">
          This site is protected by reCAPTCHA and the Google 
        <a href="https://policies.google.com/privacy"> Privacy Policy </a>
         and 
         <a href="https://policies.google.com/terms"> Terms of Service </a>
         apply.
        </div>
    </div>
  );
};

export default Contact;
