import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import './footer.scss'

const Footer = (props) => {
    return (
        // <div className='container'>
        //     <div className='row bg-dark row'>
        //         <div className='col-sm-8'>
        //             <div className='mid-a'>
        //                 <h1>Talk To Us</h1>
        //                 <p style={{width:'50%', fontWeight: '500'}}>
        //                 If you are looking for a turnkey CRE development or 
        //                 construction management partner, reach out.
        //                 </p>
        //             </div>
        //             <div className='col-sm-8'>
        //                 RBC Tower, 3300 Hwy 7 Ste. 600<br></br>
        //                 Vaughan, ON, L4K 4M3 <br></br>
        //                 <a href='tel:416-800-8764'
        //                     style={{textDecoration:'none', color: 'white'}}
        //                     >Telephone: (416) 800-8764<br></br></a>
                        
        //                 Fax: (416) 849-0598 <br></br>
        //                 <a href='mailto:admin@dclgrp.com'
        //                     style={{textDecoration:'none', color: 'white'}}
        //                     >admin@dclgrp.com</a>

        //             </div>
                    
        //         </div>
        //         <div className='col-sm-3'>
        //             <ul>
        //             {/* {
        //                 props.navheaders.map((value, index) => (
        //                     <li><a className='footer-a' href={`/${value}`}>{value}</a></li>
        //                 ))
        //             } */}

        //                 <li><a className='footer-a' href="/services">Services</a></li>
        //                 <li><a className='footer-a' href="/about-us">About Us</a></li>
        //                 <li><a className='footer-a' href="/careers">Careers</a></li>
        //                 <li><a className='footer-a' href="/contact">Contact</a></li>
        //             </ul>
        //         </div>
                
        //     </div>
        //     <div className='bottom'>
        //         © 2023 All Rights Reserved
        //     </div>
        // </div>
        <MDBFooter className='col text-light fw-bold' style={{backgroundColor: "#004986"}}>

      {/* <section className='col-lg-8 text-light fw-bold align-self-center'> */}
        <MDBContainer className='text-center text-md-start d-flex'>
          <MDBRow className='mt-3 gap-4'>
            <MDBCol md='1' lg='3' xl='3' className='mb-4 ms-5 text-start d-sm-none d-lg-block'>
              <h1 className='fw-bold mb-4'>
                Talk to us.
              </h1>
              <p>
              If you are looking for a turnkey CRE development or construction management partner, reach out.
              </p>
            </MDBCol>

            <MDBCol  md='5' lg='5' xl='5' className='align-self-center mx-auto px-0 mt-1 col-sm-8 text-start '>
              <p>
                RBC Tower, 3300 Hwy 7 Ste. 600
                Vaughan, ON, L4K 4M3
              </p>
              <p>
                <a style={{textDecoration:'none', color: 'white'}} href='tel:416-800-8764' className='text-reset'>
                Telephone: (416) 800-8764
                </a>
              </p>
              <p>
                Fax: (416) 849-0598
              </p>
              <p>
                <a style={{textDecoration:'none', color: 'white'}} href='mailto:admin@dclgrp.com' className='text-reset'>
                admin@dclgrp.com
                </a>
              </p>
            </MDBCol>

            <MDBCol md='5' lg='3' xl='3' className='align-self-center d-flex col justify-content-end mx-auto mt-1 mb-1 text-start position-relative end-0'>
              <p>
                <a id='footer-a'  href='/services' className='text-reset ps-2' style={{borderLeft: "2px solid #79c000"}}>
                  Services
                </a>
              </p>
              <p>
                <a id='footer-a' href='/about-us' className='text-reset ps-2 w-100' style={{borderLeft: "2px solid #79c000"}}>
                  About Us
                </a>
              </p>
              <p>
                <a id='footer-a' href='/careers' className='text-reset ps-2 w-100' style={{borderLeft: "2px solid #79c000"}}>
                  Careers
                </a>
              </p>
              <p>
                <a id='footer-a' href='/contact' className='text-reset ps-2' style={{borderLeft: "2px solid #79c000"}}>
                  Contact
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      {/* </section> */}

      <div className='bg-dark text-light text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 All Rights Reserved.
      </div>
    </MDBFooter>
    )
}

export default Footer;