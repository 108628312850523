import React, { useState, useEffect } from "react";
import "./history.scss";
import "../../index.scss";

import img1 from "../../media/history1.jpeg";
import img2 from "../../media/history2.jpg";
import img3 from "../../media/history3.jpg";
import img4 from "../../media/history4.jpg";
import ImageCarousel from "../imagecarousel/imagecarousel";

import Button from 'react-bootstrap/Button';

const History = (props) => {
  const [image, setImage] = useState(0);
  const images = [img1, img2, img3, img4];

  useEffect(() => {
    setTimeout(() => {
      if (image >= images.length - 1) {
        setImage(0);
      } else {
        setImage(image + 1);
      }
    }, 2500);
  });
  if (document.readyState === "complete") {
    document.querySelector(".navbar-container").style.background =
      "linear-gradient(270deg, rgba(0,73,134,1) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)";
  }

  return (
    <div>
      <div className="history-container">
        <h1
          className="fade page-header fw-semibold link-offset-2 fs-1"
          style={{ "--timing": "300ms", "--offset": "1.25vh" }}
        >
          Our History
        </h1>
        <div className="d-md-flex flex-lg-row flex-sm-column">
          <div className="fs-5">
            <p
              className="history-desc slideRight "
              style={{ "--order": 1, "--timing": "300ms" }}
            >
              In 2002, Frank Deluca started renovating homes.  Shortly 
              thereafter, he moved into commercial construction and by 2004 he 
              developed a construction delivery model for the franchise 
              industry with US-based Baskin Robbins as his first client. ​​
              This led to DCL becoming the industry leader in franchise 
              development and construction for US brands it is today.{" "}
            </p>
            <p
              className="history-desc slideRight "
              style={{ "--order": 2, "--timing": "300ms" }}
            >
              Mr. Deluca has invested over twenty-five years on construction 
              and real estate development, and through various subsidiaries of 
              DCL, he has been instrumental in the hospitality and QSR industries, 
              as well as the healthcare development industry. With over $750M in 
              development and construction to his name, he is clearly an 
              industry veteran and leads the DCL team on a daily basis.
            </p>
            <p
              className="history-desc slideRight "
              style={{ "--order": 3, "--timing": "300ms" }}
            >
              With offices in Toronto, ON, and Tampa, Florida, DCL is 
              expanding its expertise, offering National and International 
              clients boots on the ground in both countries. Regardless of if 
              we act as your CM, or as a potential development partner, it's 
              worth talking to us. ​
            </p>
          </div>
          <div className="d-flex justify-content-center historySliderDiv object-fit-cover">
            <ImageCarousel images={images} />
          </div>
        </div>
        <a
          href="mailto:admin@dclgrp.com"
          className="cta fade"
          style={{
            textDecoration: "none",
            color: "black",
            "--order": 1,
            "--timing": "300ms",
          }}
        >
              <div className='pb-0 mb-0  mt-sm-5 mt-lg-0 cta'>
              <i>"Business is a contact sport. Let’s connect"</i> – Frank Deluca,
              CEO ​
                    <a href="/contact"><button className='cta-btn' >Contact Us</button></a>
                </div>
          
        </a>
      </div>
    </div>
  );
};

export default History;
