import React from 'react';

import './slider.scss'
import 'react-slideshow-image/dist/styles.css'
import sample from '../../media/dclbackground.mp4'

const Slider = (props) => {

    return (
        <div>
            <video className='videoTag fade mx-0 my-lg-0 my-sm-5' style={{"--timing":"300ms", width:"-webkit-fill-available", marginTop: '-9.2rem'}}autoPlay loop muted>
                <source style={{objectFit: 'cover', height: '15rem'}}src={sample} type='video/mp4' />
            </video> 
        </div>
    )
}

export default Slider;