import React, { useState } from 'react'
import '../contactus/contact.scss'
import '../countrydropdown/countrydropdown.scss'
import Form from 'react-bootstrap/Form';

const CountryProvinceSelector = () => {
    
    const canadaProvinces = [
        "Alberta", "British Columbia", "Manitoba", "New Brunswick", 
        "Newfoundland and Labrador", "Nova Scotia", "Ontario"," Prince Edward Island",
        "Quebec", "Saskatchewan", "Northwest Territories", "Nunavut", "Yukon"
    ]
    const [provincestateoptions, setOptions] = useState(canadaProvinces)
    const usStates = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Minor Outlying Islands', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'U.S. Virgin Islands', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming']

    const checkCountry = () => {
        // Set provincestateoptions based on country option
        if (document.readyState === "complete") {
            let country = document.querySelector(".country").value;
            switch(country) {
                case "USA":
                    setOptions(usStates)
                    break;
                default:
                    setOptions(canadaProvinces)
                    break;
            }
        }
    }
    return (
        <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Label className='fw-bold fs-3' htmlFor='country'>Location</Form.Label><br></br>
            <div className='d-flex flex-row'>
                <Form.Select id='country' className='country fs-4' name='country' required onChange={checkCountry}>
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                </Form.Select>
                <Form.Select className='secondary fs-4' required>
                    { provincestateoptions.map((x) => <option value={x}>{x}</option>)}
                </Form.Select>
            </div>
                
            
        </Form.Group>
    )
}

export default CountryProvinceSelector;