import React from 'react';
import './navbar.scss'
import '../../index.scss'
import logo from '../../media/result.svg'
import Navbar from 'react-bootstrap/Navbar';
import { Container } from 'react-bootstrap';

import Nav from 'react-bootstrap/Nav';

import Offcanvas from 'react-bootstrap/Offcanvas';

const DCLNavbar = (props) => {
  
    // Eventually need to use react router in order to actually navigate to these pages
    let expand = "lg"
    return (
        <div className='navbar-container bg-gradient' style={{"--bs-gradient": "linear-gradient(270deg, rgba(0,73,134,1) 50%, rgba(255,255,255,1) 75%, rgba(255,255,255,1) 100%)"}}>
            <Navbar  variant='dark' expand={expand} className="container-fluid">
              <Container fluid>
                <Navbar.Brand href="/">
                  <img src={logo}  height="75" alt="DCL"></img>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header className='' closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                      <img src={logo}  height="100" alt="DCL Group of Companies"></img>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body className='bg-gradient' style={{"--bs-gradient": "var(--navbar-bg-size)"}}> 
                    <Nav className="justify-content-end flex-grow-1 pe-3 gap-5 me-5">
                      <Nav.Link className="linked" href="/">Home</Nav.Link>
                      <Nav.Link className="linked" href="/services">Services</Nav.Link>
                      <Nav.Link className="linked" href="/projects">Projects</Nav.Link>
                      <Nav.Link className="linked" href="/about-us">About Us</Nav.Link>
                      <Nav.Link className="linked" href="/careers">Careers</Nav.Link>
                      <Nav.Link className="linked" href="/contact">Contact Us</Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
        </div>
      );
}


export default DCLNavbar;