import "./App.css";

import Slider from "./components/slider/slider";
import Projects from "./components/projects/projects";
import "./index.scss";
import committee1 from "./media/Web Members Logos/SBC_logo-2016-removebg-preview.png";
import committee3 from "./media/Web Members Logos/ULI-Main-RGB-Color-Logo-removebg-preview.png"

function Home() {
  return (
    <div>
      <Slider/>
      <div className="justify-content-center d-block">
        <div className="px-5 mx-5">
          <div className="container-fluid text-center px-5 py-5 fs-2 mission-statement">
            <div className="fade mission-text" style={{ "--timing": "300ms"}}>
              <b>DCL</b> has <b>over 50 years</b> of experience as a GC, Project
              Manager, Developer, and <b>Design-Builder</b>... our history of
              projects, experience and resources is{" "}
              <b>what we bring to each project.</b>
              <br></br>
              <i className="fs-3">Frank A. Deluca</i>
            </div>
          </div>
        </div>
        <Projects />
        <div
          className="justify-content-center py-5 gap-5"
          style={{ display: "flex", textAlign: "center" }}
        >
          <div className="col-sm-9 col-lg-8">
            <img className="col-sm-5 col-lg-2 px-3"
              src={committee1}
              alt="Sustainable Buildings Canada"
              title="Sustainable Buildings Canada"
            />
            <img className="col-sm-5 col-lg-3 px-3"
              src={committee3}
              alt="Sustainable Buildings Canada"
              title="Sustainable Buildings Canada"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
