import React from 'react';
import './careers.scss'
import '../../index.scss'
import { Image } from 'react-bootstrap';
import office from '../../media/general-office-3.jpg'

const Careers = () => {
    return(
        <div className='careers-container'>
            <head>
                <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            </head>
            <h1
          className="fade fw-bold fs-1 text-center mt-5"
          style={{ "--order": 1, "--timing": "500ms" }}
            >Looking for a Career Opporunity, or Want To Work With Us?</h1>
            <p className='fs-5 text-center'>
                Then get in contact with us using the information below!
            </p>
            <div className='justify-content-center d-flex flex-column'>
                <Image className='careers-cta-image justify-self-center' fluid={true} src={office}></Image>
            <div className='d-flex w-100'>
                <div className='d-flex w-100'>

                    <div className='d-flex w-100 justify-content-start'>
                        <div className='d-flex flex-column justify-content-start'>
                            <h3 className=' pt-3 fs-3 fw-bold'>For Career Opportunities</h3>
                            <a className='careers-contact-1 fs-6'  style={{textDecoration: 'none'}} href="mailto:admin@dclgrp.com">
                                <span style={{verticalAlign: 'bottom', paddingRight:'1rem'}}class="material-symbols-outlined">
                                mail
                                </span>
                                admin@dclgrp.com
                            </a>
                        </div>
                    </div>
                    <div className='d-flex flex-column justify-content-end'>
                        <h3 className=' pt-3 fs-3 fw-bold text-end' style={{width: 'max-content'}}>For Business Opportunities</h3>
                        <a className='careers-contact-2 fs-6 d-flex' style={{alignSelf: 'flex-end', textDecoration: 'none'}} href="mailto:bid@dclgrp.com">
                            
                            bid@dclgrp.com
                            <span style={{verticalAlign: 'bottom', paddingLeft:'1rem'}}class="material-symbols-outlined">
                            mail
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Careers;