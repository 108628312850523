import React from "react";
import "./carousel.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";

const ImageCarousel = (props) => {
  return (
      <Swiper
        className="slider swiper-lazy-preloader-white"
        speed={1500}
        effect="fade"
        fadeEffect={{crossfade:true}}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {props.images.map((x) => (
          <SwiperSlide className="slide">
            <img
              className="slideImg"
              src={x}
              loading="lazy"
              alt="A project by DCL"
            />
          </SwiperSlide>
        ))}
      </Swiper>
  );
};

export default ImageCarousel;
