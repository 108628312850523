import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import DCLNavbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import History from './components/history/history';
import Home from './Home';
import Services from './components/services/services';
import Contact from './components/contactus/contact';
import Careers from './components/careers/careers';
import About from './components/about/about';
import './index.scss';
import ProjectShowcase from './components/projects-page/projectpage';
import 'bootstrap/dist/css/bootstrap.css';

const App = (props) => {
    const headers = ['About Us', 'Bidroom', 'Careers', 'Contact']
    const navigate = useNavigate();
    return(
    <div>
        <DCLNavbar/>
        <Routes navigator={navigate}>
        
            <Route path="/" element={<Home />} />
            <Route path="/history" element={<History />} />
            <Route path="/services" element={<Services />}/>
            <Route path="/projects" element={<ProjectShowcase/>}/>
            <Route path="/contact" element={<Contact />} />
            <Route path="/careers" element={<Careers />}/>
            <Route path="/about-us" element={<About/>}/>
        </Routes>
        <Footer navheaders={headers} />
    </div>
    )
}

export default App;